exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-articles-js": () => import("./../../../src/templates/archive/Articles.js" /* webpackChunkName: "component---src-templates-archive-articles-js" */),
  "component---src-templates-archive-success-stories-js": () => import("./../../../src/templates/archive/SuccessStories.js" /* webpackChunkName: "component---src-templates-archive-success-stories-js" */),
  "component---src-templates-archive-videos-js": () => import("./../../../src/templates/archive/Videos.js" /* webpackChunkName: "component---src-templates-archive-videos-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-article-js": () => import("./../../../src/templates/single/Article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-templates-single-success-story-js": () => import("./../../../src/templates/single/SuccessStory.js" /* webpackChunkName: "component---src-templates-single-success-story-js" */),
  "slice---src-slices-footer-js": () => import("./../../../src/slices/Footer.js" /* webpackChunkName: "slice---src-slices-footer-js" */),
  "slice---src-slices-main-navigation-js": () => import("./../../../src/slices/MainNavigation.js" /* webpackChunkName: "slice---src-slices-main-navigation-js" */),
  "slice---src-slices-tippy-top-js": () => import("./../../../src/slices/TippyTop.js" /* webpackChunkName: "slice---src-slices-tippy-top-js" */)
}

