import React, { useContext, createContext } from 'react';
import { has } from 'lodash';

import { isBrowser } from '~/utilities/helpers';

const LocationContext = createContext();

/**
 * During the build process theses are the only three location properties available on the server.
 *
 * @type {{search: string, hash: string, pathname: string}}
 */
const defaultLocation = {
    pathname: '',
    search: '',
    hash: ''
};

export const LocationProvider = ({ children, pageProps }) => {
    const getLocationAPI = () => {
        if (has(pageProps, 'location')) {
            return pageProps.location;
        }

        if (isBrowser()) {
            return window.location;
        }

        return defaultLocation;
    };

    return (
        <LocationContext.Provider
            value={{
                location: getLocationAPI()
            }}
        >
            {children}
        </LocationContext.Provider>
    );
};

export const useLocationContext = () => {
    const context = useContext(LocationContext);

    if (context === undefined) {
        throw new Error(
            'useLocationContext must be used within a LocationProvider'
        );
    }

    return context;
};
