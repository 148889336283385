import { ThemeProvider } from '@emotion/react';
import React from 'react';

import THEME from '~/theme';

import { GravityFormsProvider } from '~/context/GravityFormsContext';
import { LocationProvider } from '~/context/LocationContext';

const GlobalContext = ({ element, props }) => {
    return (
        <LocationProvider pageProps={props}>
            <GravityFormsProvider>
                <ThemeProvider theme={THEME}>{element}</ThemeProvider>
            </GravityFormsProvider>
        </LocationProvider>
    );
};

export const wrapRootElement = ({ element }) => {
    return <GlobalContext element={element} />;
};

export const wrapPageElement = ({ element, props }) => {
    return <GlobalContext element={element} props={props} />;
};
