import { getElementFromHash, scrollToElement } from './src/utilities/helpers';
import theme from './src/theme';
export { wrapRootElement } from './gatsby-ssr';

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location?.hash && location?.state?.isJumpLink) {
        const element = getElementFromHash(location.hash);
        scrollToElement(element, parseInt(theme.layout.headerHeight));
        return true;
    }
};
