import { upperFirst, camelCase } from 'lodash';
import * as sanitizeHTML from 'sanitize-html';
import parse from 'html-react-parser';
import articleThumbnailPlaceholder from '~/images/article-thumbnail-placeholder.jpg';
import { uniqueId } from 'lodash';

export const isBrowser = () => typeof window !== 'undefined';

export const blockNameTransform = (name) => {
    let type, block;
    [type, block] = name.split('/');

    type = upperFirst(camelCase(type));
    block = upperFirst(camelCase(block));

    return `${type}/${block}Block`;
};

export const sanitizeAndParseHTML = (html, parseOptions = {}) => {
    return parse(
        sanitizeHTML(html, {
            allowedTags: false,
            allowedAttributes: false,
            allowVulnerableTags: true
        }),
        parseOptions
    );
};

export const hexToRgba = (hex, opacity) => {
    const hexValue = hex.replace('#', '');
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);
    return `rgba( ${r}, ${g}, ${b}, ${opacity} )`;
};

/**
 * @param url
 * @param size
 * @returns {string}
 */
export const getYouTubeThumbnailUrl = (url, size) => {
    if (url === null) {
        return '';
    }

    size = size === null ? 'big' : size;

    const match = url.match('[\\?&]v=([^&#]*)');
    const video = match === null ? url : match[1];

    if (size === 'small') {
        return 'http://img.youtube.com/vi/' + video + '/2.jpg';
    }

    return 'http://img.youtube.com/vi/' + video + '/0.jpg';
};

export const parseArticlePreviewNodes = (wpPosts) => {
    const nodes = wpPosts?.nodes ?? wpPosts;
    const placeholderImage = {
        sourceUrl: articleThumbnailPlaceholder,
        id: uniqueId(),
        altText: 'Photo of car wash'
    };

    return nodes.map((post) => ({
        title: post.title,
        subtitle: post?.categories?.nodes[0]?.name ?? '',
        image:
            post.featuredImage?.node ?? post.featuredImage ?? placeholderImage,
        link: {
            title: 'Read Article',
            url: getPathFromUrl(post.uri),
            target: '_self'
        }
    }));
};

export const parseVideoNodes = (wpVideos) => {
    const nodes = wpVideos?.nodes ?? wpVideos;

    return nodes.map((video) => ({
        title: video.title,
        url: video.video.videoUrl,
        thumbnail: video.video.thumbnail
    }));
};

export const parseSuccessStoryPreviewNodes = (wpPosts) => {
    const nodes = wpPosts?.nodes ?? wpPosts;

    return nodes.map((story) => ({
        title: story.title,
        id: story.id,
        subtitle: story?.cptSuccessStories?.bio ?? '',
        image: story.featuredImage.node,
        link: {
            url: getPathFromUrl(story.uri),
            title: 'Read Success Story',
            target: '_self'
        }
    }));
};

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return null;
};

export function scrollToElement(element, offset = 0) {
    if (!element) return;

    const y = element.getBoundingClientRect().top + window.pageYOffset - offset;

    window.scrollTo({
        top: y
    });
}

export function getElementFromHash(hash) {
    const trimmedHash = hash.replaceAll('/', '');
    let element = null;

    try {
        element = document.querySelector(trimmedHash);
    } catch (e) {
        if (isBrowser) {
            console.error(e);
        }
    }

    return element;
}

export function getPathFromUrl(url) {
    const urlObj = new URL(url);

    if (urlObj && urlObj?.pathname) {
        return urlObj.pathname;
    }

    return url;
}

export const STATES = {
    DC: 'Washington, D.C.',
    DE: 'Delaware',
    GA: 'Georgia',
    KY: 'Kentucky',
    MD: 'Maryland',
    NC: 'North Carolina',
    NJ: 'New Jersey',
    PA: 'Pennsylvania',
    SC: 'South Carolina',
    TN: 'Tennessee',
    VA: 'Virginia',
    WV: 'West Virginia'
};
