export const palette = {
    black: '#000D1F',
    white: '#FFFFFF',
    offWhite: '#FCFAF8',
    darkBlue: '#002F6C',
    lightBlue: '#99E1FF',
    mediumBlue: '#00A2E8',
    borderBlue: '#006AAE',
    blueWhite: '#CDEFFE',
    leadLine: '#0176BC',
    tuscany: '#EDA85A',
    alternate: {
        darkerBlue: '#001e47',
        darkBlue: '#005CA4',
        mediumBlue: '#0099DB',
        lightBlue: '#B7E8FD'
    },
    transparent: 'transparent'
};

export const gradient = {
    darkBlue: `linear-gradient(164deg, ${palette.darkBlue} 13%, ${palette.alternate.darkBlue} 95%)`,
    mediumBlue: `linear-gradient(168deg, #00357A 9%, ${palette.darkBlue} 100%)`,
    alternate: {
        darkBlue: `linear-gradient(163deg, #001838 0%, ${palette.black} 99%)`,
        mediumBlue: `linear-gradient(168deg, #00357A 9%, ${palette.darkBlue} 100%)`
    }
};

const fontFamily = {
    heading: 'DM Sans, sans-serif',
    body: 'Inter, sans-serif',
    button: 'Epilogue, sans-serif'
};

const fontWeight = {
    regular: 400,
    medium: 500,
    bold: 700
};

export const typography = {
    fontFamily,
    fontWeight,
    h1: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeight.bold,
        fontSize: '4.8rem'
    },
    h2: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeight.bold,
        fontSize: '3.6rem'
    },
    h3: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeight.bold,
        fontSize: '3.4rem'
    },
    h4: {
        fontFamily: fontFamily.heading,
        fontWeight: fontWeight.bold,
        fontSize: '2rem'
    },
    p: {
        fontFamily: fontFamily.body,
        fontWeight: fontWeight.regular,
        fontSize: '1.8rem'
    }
};

export const animation = {
    duration: {
        fast: '0.2s',
        medium: '0.35s',
        slow: '0.6s'
    }
};

export const breakpoints = {
    largeDesktop: '1440px',
    desktop: '1200px',
    largeTablet: '1024px',
    largeTabletMax: '1023px',
    tablet: '768px',
    tabletMax: '767px',
    largePhone: '412px',
    phone: '360px'
};

export const breakpointNumbers = {
    largeDesktop: 1440,
    desktop: 1200,
    largeTablet: 1024,
    tablet: 768,
    largePhone: 412,
    phone: 360
};

export const layout = {
    maxContentWidth: '1440px',
    pageGutter: '25px',
    pageGutterTablet: '40px',
    gridGutter: '20px',
    sectionPadding: '120px',
    sectionPaddingTablet: '90px',
    sectionPaddingMobile: '70px',
    sectionPaddingMedium: '65px',
    sectionPaddingMediumTablet: '50px',
    sectionPaddingMediumMobile: '40px',
    sectionPaddingSmall: '40px',
    sectionPaddingSmallTablet: '40px',
    sectionPaddingSmallMobile: '30px',
    paragraphSpacing: '20px',
    headerHeight: '117px'
};

export const zIndex = {
    belowHeader: 2,
    header: 3,
    aboveHeader: 4
};

export default {
    palette,
    gradient,
    typography,
    animation,
    layout,
    breakpoints,
    breakpointNumbers,
    zIndex
};
